import axios from 'axios';
import { LocalStorageKeys } from 'constants/LocalStorageKeys';
import { IP_INFO_TOKEN, IP_INFO_URL } from 'constants/env';
import { saveLanguage } from 'helpers/language.helpers';
import { loadFromSessionStorage, saveToSessionStorage } from 'services/storage';

export const getIpInfo = async (isFlowOuter?: boolean) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const langQueryParam = urlParams.get('lang');
    const flowOuter = isFlowOuter || urlParams.get('flowOuter');

    try {
        const response = await axios.get(`${IP_INFO_URL}`, {
            params: { key: IP_INFO_TOKEN },
        });

        const { query, city, countryCode, zip, lat, lon, timezone, regionName } = response.data || {};

        saveToSessionStorage(LocalStorageKeys.COUNTRY, countryCode);

        const language = loadFromSessionStorage(LocalStorageKeys.LANGUAGE) || (flowOuter ? langQueryParam?.toUpperCase() || countryCode || 'EN' : 'EN');
        saveLanguage(language);

        return {
            ip: query,
            city,
            country: countryCode,
            postal: zip,
            loc: [lat, lon].join(','),
            timezone,
            state: regionName,
        };
    } catch (e) {
        console.error('error:', e);
    }
};
