import { Stack, Typography, styled } from '@mui/material';
import { Languages } from 'enums/languages.enum';
import { getLanguageOptions, saveLanguage } from 'helpers/language.helpers';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
    onClose: () => void;
}

const StyledLanguagePicker = styled(Stack)(() => ({
    position: 'absolute',
    left: '50%',
    bottom: '0',
    transform: 'translate(-50%, 105%)',
    width: 'fit-content',
    maxWidth: '200px',
    padding: '1rem',
    borderRadius: '1rem',
    background: '#12162B',
}));

const StyledLanguageOption = styled(Typography)(() => ({
    cursor: 'pointer',
    color: '#D3D3DD',
    transition: '.15s',
    fontWeight: 300,
    lineHeight: '20px',
    '&.active, &:hover': {
        color: '#01CFFE',
    },
}));

export const LanguagePicker: FC<Props> = ({ onClose }) => {
    const { i18n } = useTranslation();
    const handleLanguagePick = (id: Languages) => {
        saveLanguage(id.toUpperCase());
        onClose();
    };

    return (
        <StyledLanguagePicker gap=".5rem">
            {getLanguageOptions?.map((lang) => (
                <StyledLanguageOption
                    key={lang.id}
                    className={lang.id.toLowerCase() === i18n.language.toLowerCase() ? 'active' : undefined}
                    onClick={() => handleLanguagePick(lang.id)}
                >
                    {lang.title}
                </StyledLanguageOption>
            ))}
        </StyledLanguagePicker>
    );
};
