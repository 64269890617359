import { styled } from '@mui/material/styles';
import LoadingButton from '../LoadingButton';

const GradientButton = styled(LoadingButton)((props) => ({
    width: '100%',
    padding: '22px 0px 19px 0px',
    borderRadius: '40px',
    fontSize: '20px',
    color: '#fff',
    fontWeight: 700,
    background: 'linear-gradient(90deg, #0A2693 0%, #01CFFE 63.03%, #F2D3CD 100%)',
    border: 'none !important',
    textTransform: 'capitalize',

    ...(props.variant === 'outlined'
        ? {
              '&::before': {
                  content: '""',
                  position: 'absolute',
                  inset: '0',
                  borderRadius: '40px',
                  padding: '1px',
                  background:
                      'linear-gradient(90deg, #0A2693 0%, #01CFFE 63.03%, #F2D3CD 100%), linear-gradient(0deg, #FFFFFF, #FFFFFF);\n' +
                      '  -webkit-mask: \n' +
                      '     linear-gradient(#fff 0 0) content-box, \n' +
                      '     linear-gradient(#fff 0 0);\n' +
                      '          mask: \n' +
                      '     linear-gradient(#fff 0 0) content-box, \n' +
                      '     linear-gradient(#fff 0 0);\n' +
                      '  -webkit-mask-composite: xor;\n' +
                      '          mask-composite: exclude;',
              },
          }
        : {}),

    lineHeight: 'normal',
    '&.Mui-disabled': {
        color: '#fff',
        opacity: 0.5,
    },
}));

export default GradientButton;
