import { Container, Stack, styled } from '@mui/material';

export const DesktopMenuWrapper = styled(Stack)(() => ({
    position: 'sticky',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 10,
    height: '82px',
    flexDirection: 'row',
    alignItems: 'center',
}));

export const DesktopMenuContainer = styled(Container)(({ theme }) => ({
    display: 'grid',
    backgroundColor: theme.palette.background.gray,
    alignItems: 'center',
    height: '100%',
    gridTemplateColumns: '1fr 1fr',
    borderRadius: '0px 0px 24px 24px',

    [theme.breakpoints.down('lmd')]: {
        borderRadius: '0',
    },
}));
