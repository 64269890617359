import { useState } from 'react';
import { Dialog, DialogContent, DialogTitle, Stack, styled, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { UnsubscribeForm } from './UnsubscribeForm';

const StyledFormTrigger = styled(Typography)({
    color: '#a3a5b0',
    fontSize: '16px',
    textDecoration: 'underline',
    marginBottom: '20px',
    fontWeight: 300,
    cursor: 'pointer',
});

const StyledModal = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        color: theme.palette.text.black,
        borderRadius: '1rem',
        background: 'linear-gradient(128deg, rgba(10, 38, 147, 0.20) 0%, rgba(1, 207, 254, 0.20) 70.88%, rgba(242, 211, 205, 0.20) 96.12%), #FFF',
        padding: '29px 43px',
        width: '100%',
        maxWidth: '436px',
    },
    [theme.breakpoints.down('sm')]: {
        '& .MuiDialog-paper': {
            padding: '12px 12px 27px',
            margin: '8px',
        },
    },
}));

const UnsubscribePopup = () => {
    const [open, setOpen] = useState(false);
    const [formSent, setFormSent] = useState(false);

    const handleOpen = () => {
        setFormSent(false);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setTimeout(() => {
            setFormSent(false);
        }, 300);
    };

    const handleFormSubmit = () => {
        setFormSent(true);
    };

    return (
        <div>
            <StyledFormTrigger onClick={handleOpen}>Cancel Subscription</StyledFormTrigger>

            <StyledModal open={open} onClose={handleClose}>
                <CloseIcon onClick={handleClose} sx={{ ml: 'auto', cursor: 'pointer' }} />
                {!formSent ? (
                    <>
                        <DialogTitle sx={{ margin: '0 0 24px', padding: 0, textAlign: 'center', fontSize: '28px', lineHeight: '35px', fontWeight: 700 }}>
                            Cancel Subscription
                        </DialogTitle>
                        <DialogContent sx={{ padding: 0, margin: 0 }}>
                            <UnsubscribeForm onSubmit={handleFormSubmit} />
                        </DialogContent>
                    </>
                ) : (
                    <Stack alignItems="center" sx={{ textAlign: 'center' }} padding="0 20px 20px">
                        <img src={'/images/success_illustration.svg'} alt="success illustration" style={{ marginBottom: '30px' }} />
                        <Typography fontSize="38px" fontWeight={700} mb="20px">
                            Success
                        </Typography>
                        <Typography fontSize="20px" fontWeight={500} mb="30px" color="text.disableDark">
                            Your request has been received. We will process it as soon as possible
                        </Typography>
                        <Typography fontSize="18px" color="#72789a">
                            If you have any further questions, please contact our support team at{' '}
                            <a href="mailto:support@locatemate.co" style={{ color: '#72789a' }}>
                                support@locatemate.co
                            </a>
                        </Typography>
                    </Stack>
                )}
            </StyledModal>
        </div>
    );
};

export default UnsubscribePopup;
