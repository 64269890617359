import { Grid, InputLabel, styled, TextField, Typography } from '@mui/material';
import GradientButton from 'components/GradienButton';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { supabase } from 'services/supabase';

interface BodyPayload {
    email: string;
    fullName: string;
    firstCardDigits: string;
    lastCardDigits: string;
}

const StyledInput = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-root': {
        backgroundColor: '#fff',
        color: '#0C0F21',
        fontSize: '18px',
        fontWeight: 300,
        borderRadius: '12px',
        '& ::placeholder': { color: theme.palette.text.grey, fontWeight: '300', opacity: '1' },
    },
    '& input': { p: '18px 20px' },
    '& .MuiOutlinedInput-input:-webkit-autofill': {
        WebkitBoxShadow: '0 0 0 1000px #fff inset',
        WebkitTextFillColor: '#0C0F21',
    },
    '& .MuiFormHelperText-root': {
        mt: '6px',
        mb: '6px',
        fontWeight: '300',
        color: '#FB5959',
    },
}));

const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
    fontSize: '14px',
    color: theme.palette.text.disableDark,
}));

interface Props {
    onSubmit?: () => void;
}

export const UnsubscribeForm: FC<Props> = ({ onSubmit }) => {
    const [isLoading, setIsLoading] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        defaultValues: {
            fullName: '',
            email: '',
            firstSixCardDigits: '',
            lastFourCardDigits: '',
        },
    });

    const onFormSubmit = async (formData) => {
        const { email, firstSixCardDigits: firstCardDigits, lastFourCardDigits: lastCardDigits, fullName } = formData;
        try {
            setIsLoading(true);
            await supabase.functions.invoke('cancel-subscription', {
                body: {
                    email,
                    fullName,
                    firstCardDigits,
                    lastCardDigits,
                } as BodyPayload,
            });
            reset();
            onSubmit?.();
        } catch (err) {
            console.error("couldn't send cancellation email. reason: ", err);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container spacing={2} sx={{ mb: '1rem' }}>
                <Grid item xs={12}>
                    <StyledInputLabel shrink htmlFor="fullName">
                        Full name*
                    </StyledInputLabel>
                    <StyledInput
                        id="fullName"
                        placeholder="Your Name"
                        fullWidth
                        variant="outlined"
                        {...register('fullName', {
                            required: 'Full name is required.',
                            pattern: {
                                value: /^[a-zA-Z\s]+$/,
                                message: 'Please enter a valid name.',
                            },
                        })}
                        error={!!errors.fullName}
                        helperText={errors.fullName?.message}
                    />
                </Grid>

                <Grid item xs={12}>
                    <StyledInputLabel shrink htmlFor="email">
                        Email Address*
                    </StyledInputLabel>
                    <StyledInput
                        id="email"
                        type="email"
                        placeholder="email@example.com"
                        fullWidth
                        variant="outlined"
                        {...register('email', {
                            required: 'Email is required.',
                            pattern: {
                                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                message: 'Please enter a valid email address.',
                            },
                        })}
                        error={!!errors.email}
                        helperText={errors.email?.message}
                    />
                </Grid>

                <Grid item xs={6}>
                    <StyledInputLabel shrink htmlFor="firstSixCardDigits">
                        First 6 Card Digits*
                    </StyledInputLabel>
                    <StyledInput
                        id="firstSixCardDigits"
                        type="text"
                        fullWidth
                        variant="outlined"
                        placeholder="XXX-XXX"
                        {...register('firstSixCardDigits', {
                            required: 'First 6 card digits are required.',
                            pattern: {
                                value: /^\d{6}$/,
                                message: 'First 6 card digits must contain exactly 6 digits.',
                            },
                        })}
                        inputProps={{ maxLength: 6 }}
                        error={!!errors.firstSixCardDigits}
                        helperText={errors.firstSixCardDigits?.message}
                    />
                </Grid>

                <Grid item xs={6}>
                    <StyledInputLabel shrink htmlFor="lastFourCardDigits">
                        Last 4 Card Digits*
                    </StyledInputLabel>
                    <StyledInput
                        id="lastFourCardDigits"
                        type="text"
                        fullWidth
                        variant="outlined"
                        placeholder="XXXX"
                        {...register('lastFourCardDigits', {
                            required: 'Last 4 card digits are required.',
                            pattern: {
                                value: /^\d{4}$/,
                                message: 'Last 4 card digits must contain exactly 4 digits.',
                            },
                        })}
                        inputProps={{ maxLength: 4 }}
                        error={!!errors.lastFourCardDigits}
                        helperText={errors.lastFourCardDigits?.message}
                    />
                </Grid>
            </Grid>
            <Typography color="text.disableDark" fontSize="12px" lineHeight="15px" mb="1rem">
                Note: Your privacy is important to us. We will use this information solely for verification purposes and will not share it with any third
                parties.
            </Typography>
            <GradientButton type="submit" loading={isLoading} disabled={Object.keys(errors).length > 0} sx={{ borderRadius: '10px' }}>
                Submit
            </GradientButton>
        </form>
    );
};
