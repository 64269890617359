import { DesktopMenuContainer, DesktopMenuWrapper } from './Menu.styled';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, Stack } from '@mui/material';
import Logo from 'components/Logo/Logo';
import BurgerMenu from 'components/BurgerMenu';
import { useBoolean } from 'hooks/useBoolean';

const DesktopMenu = () => {
    const [open, setOpen] = useBoolean();

    return (
        <DesktopMenuWrapper>
            <DesktopMenuContainer maxWidth="lmg">
                <Logo height={'32px'} fontSize="18px" />
                <Stack direction="row" justifyContent="end">
                    <IconButton size="large" edge="end" color="inherit" aria-label="menu" onClick={setOpen.toggle}>
                        <MenuIcon />
                    </IconButton>
                </Stack>
            </DesktopMenuContainer>
            <BurgerMenu open={open} anchor="right" onClose={setOpen.off} />
        </DesktopMenuWrapper>
    );
};

export default DesktopMenu;
