export enum EndpointEnum {
    SIGNUP = '/auth/signup',
    SIGNIN = '/auth/signin',
    REFRESH_TOKEN = '/auth/refresh',
    USERS = '/users/data',
    LOCATIONS = '/locations',
    PHONE = '/phone',
}

export enum EndpointsPayment {
    USER_CREATE = '/users',
    USER_GET = '/users',
    USER_SUBSCRIPTION_GET = '/users/check-subscription',
    PAYMENT_ONE_CLICK_GET = '/users/payment-data',
    SUBSCRIPTION_PLANS_GET = '/subscription-plan',
    SUBSCRIPTION_TRANSACTION_CREATE = '/ixopay/purchase/subscription',
    INAPP_TRANSACTION_CREATE = '/ixopay/purchase/inapp',
    CREATE_PAYPAL_SUBSCRIPTION = '/subscriptions/create',
}

export enum EndpointsAttribution {
    USER_ATTRIBUTION_CREATE = '/web-user-info',
}
