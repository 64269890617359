import { useTranslation } from 'hooks/useTranslation';
import { PropsWithChildren, useEffect } from 'react';

export const DirectionWrapper = ({ children }: PropsWithChildren) => {
    const { detect } = useTranslation();

    useEffect(() => {
        const dir = detect.isArabicLanguage ? 'rtl' : 'ltr';
        document.documentElement.setAttribute('dir', dir);
    }, [detect.isArabicLanguage]);

    return <>{children}</>;
};
