import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import Footer from 'components/Footer';
import { DesktopMenu } from './Menu';

const AccountLayout = () => {
    return (
        <Box minHeight="100svh" position="relative" display="flex" flexDirection="column">
            <DesktopMenu />
            <Outlet />
            <Footer />
        </Box>
    );
};

export default AccountLayout;
