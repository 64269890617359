import { FC, ReactNode, useCallback } from 'react';
import { ButtonProps, CircularProgress } from '@mui/material';
import { Button } from '@mui/material';

export interface LoadingButtonProps extends ButtonProps {
    loading?: boolean;
    loadingIndicator?: ReactNode;
    loadingPosition?: 'start' | 'end' | 'center';
}

const LoadingButton: FC<LoadingButtonProps> = ({ disabled, loading, loadingIndicator, loadingPosition = 'center', ...props }) => {
    const indicator = loadingIndicator ?? <CircularProgress size={20} color="inherit" />;

    const getLoadingButtonProps = useCallback(() => {
        const loadingButtonProps: LoadingButtonProps = { ...props };

        switch (loadingPosition) {
            case 'center':
                loadingButtonProps.endIcon = null;
                loadingButtonProps.startIcon = null;
                loadingButtonProps.children = loading ? indicator : props.children;
                return loadingButtonProps;
            case 'start':
                loadingButtonProps.startIcon = loading ? indicator : props.startIcon;
                return loadingButtonProps;
            case 'end':
                loadingButtonProps.endIcon = loading ? indicator : props.endIcon;
                return loadingButtonProps;
            default:
                return loadingButtonProps;
        }
    }, [indicator, loading]);

    return <Button disabled={disabled || loading} {...getLoadingButtonProps()} />;
};

export default LoadingButton;
