import { Button, Stack, Typography, CircularProgress, TextField, TextareaAutosize } from '@mui/material';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { useMemo } from 'react';
import { useAppData } from 'providers/RootStoreProvider';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import { useBoolean } from 'hooks/useBoolean';
import { ActionItem, Modal } from 'components/DevToolsWidget/components/shared';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { observer } from 'mobx-react-lite';

interface Fields {
    fbconfig: string;
}

const schema = z.object({
    fbconfig: z.string().refine((data) => {
        try {
            const result = JSON.parse(data);
            return typeof result === 'object' && result !== null && !Array.isArray(result);
        } catch {
            return false;
        }
    }, 'Invalid JSON object'),
});

const FirebaseConfigForm = observer(() => {
    const { abTestData, setAbTestData } = useAppData();
    const [isOpen, setIsOpen] = useBoolean(false);

    const abTestDataStringified = useMemo(() => JSON.stringify(abTestData, null, 2), [abTestData]);

    const {
        control,
        handleSubmit,
        formState: { isSubmitting },
    } = useForm<Fields>({
        resolver: zodResolver(schema),
        defaultValues: {
            fbconfig: abTestDataStringified,
        },
    });

    const submit: SubmitHandler<Fields> = async (data): Promise<void> => {
        await setAbTestData(JSON.parse(data.fbconfig));
        setIsOpen.off();
    };

    return (
        <ActionItem
            tooltipTitle={'Firebase Config'}
            FabProps={{ disabled: !abTestData }}
            icon={
                <>
                    <Stack justifyContent="center" alignItems="center" onClick={setIsOpen.on} sx={{ width: '100%', height: '100%' }}>
                        <AltRouteIcon sx={(theme) => ({ color: theme.palette.background.blue })} />
                    </Stack>
                    <Modal open={isOpen} onClose={setIsOpen.off}>
                        <Stack direction={'column'} gap="12px">
                            <Typography align="center" variant="h2">
                                Edit Firebase Config
                            </Typography>

                            <form onSubmit={handleSubmit(submit)}>
                                <Stack direction="column" flex={1} spacing="16px">
                                    <Controller
                                        name="fbconfig"
                                        control={control}
                                        render={({ field, fieldState: { error } }) => (
                                            <TextField
                                                {...field}
                                                multiline
                                                minRows={3}
                                                variant="outlined"
                                                disabled={isSubmitting}
                                                error={!!error}
                                                helperText={error ? error.message : null}
                                                fullWidth
                                                InputProps={{
                                                    inputComponent: TextareaAutosize,
                                                    inputProps: {
                                                        onKeyDown: (e) => {
                                                            e.stopPropagation();
                                                        },
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                    <Button
                                        type="submit"
                                        color="primary"
                                        disabled={isSubmitting}
                                        disableRipple
                                        disableElevation
                                        sx={{
                                            gap: '6px',
                                            backgroundColor: 'text.lightBlue',
                                            borderRadius: '12px',
                                            color: 'background.white',
                                            fontSize: '18px',
                                            border: '1px solid transparent',
                                            '&:hover': {
                                                borderColor: '#90caf9',
                                            },
                                        }}
                                    >
                                        {isSubmitting ? <CircularProgress size={24} /> : <SaveOutlinedIcon />}
                                        Save
                                    </Button>
                                </Stack>
                            </form>
                        </Stack>
                    </Modal>
                </>
            }
        />
    );
});

export default FirebaseConfigForm;
