import axios from 'axios';
import { HttpResponse } from 'interfaces/http-response.interface';
import { BACKEND_URL } from 'constants/env';
import { loadTokensFromStorage, removeTokensFromStorage, saveTokensToStorage } from 'services/storage';
import { supabase } from 'services/supabase';
import { refreshAuthToken } from './auth.client';
import { AppRoutes } from 'enums/routes.enum';

const getToken = () => {
    const token = loadTokensFromStorage()?.token;
    return token || '';
};

const axiosInstance = axios.create({
    baseURL: BACKEND_URL,
});

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;
        const code = error?.response?.status;

        const logOut = () => {
            supabase.auth
                .signOut()
                .then(() => {
                    removeTokensFromStorage();
                    window.location.assign(AppRoutes.LOG_IN);
                })
                .catch((error) => {
                    console.error('error: ', error);
                });
            localStorage.setItem('existingUserLoggedOut', 'true');
        };

        if (code === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            const refreshToken = loadTokensFromStorage()?.refreshToken;
            if (refreshToken) {
                const tokens = await refreshAuthToken(refreshToken);

                if (tokens) {
                    saveTokensToStorage(tokens);

                    originalRequest.headers.Authorization = 'Bearer ' + tokens.token;
                    return axiosInstance(originalRequest);
                } else {
                    logOut();
                }
            } else {
                logOut();
            }
        }
        return Promise.reject((error.response && error.response.data) || 'Something went wrong');
    }
);

export const get = async <T = any>(endpoint: string, query: any): Promise<HttpResponse<T> | undefined> => {
    try {
        return await axiosInstance
            .get(endpoint, {
                headers: { Authorization: `Bearer ${getToken()}` },
                params: { ...query },
            })
            .catch((e) => {
                return e.response;
            });
    } catch (e) {
        console.error('error:', e);
    }
};

export const post = async <T = any>(endpoint: string, data: any): Promise<HttpResponse<T> | undefined> => {
    return await axiosInstance.post(endpoint, data, {
        headers: {
            Authorization: `Bearer ${getToken()}`,
            ContentType: 'application/json',
        },
    });
};

export const postPinCode = async <T = any>(endpoint: string, data: any): Promise<HttpResponse<T> | undefined> => {
    try {
        return await axiosInstance.post(endpoint, data);
    } catch (e) {
        console.error('error:', e);
    }
};

export const put = async <T = any>(endpoint: string, id: number | string, data: any): Promise<HttpResponse<T> | undefined> => {
    try {
        return await axiosInstance.put(`${endpoint}/${id}`, data, {
            headers: { Authorization: `Bearer ${getToken()}` },
        });
    } catch (e: any) {
        console.error('error:', e);
        return e.response;
    }
};

export const deleteApi = async <T = any>(endpoint: string, query = {}, data = {}): Promise<HttpResponse<T> | undefined> => {
    const config = {
        params: { ...query },
        data,
        headers: {
            Authorization: `Bearer ${getToken()}`,
        },
    };
    return await axiosInstance.delete(endpoint, config);
};
