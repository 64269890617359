import { Box, Container, Stack, Typography } from '@mui/material';
import Logo from 'components/Logo/Logo';
import { Link, useBeforeUnload, useLocation, useNavigate } from 'react-router-dom';
import useDeviceDetect from 'hooks/useDeviceDetect';
import { useTranslation } from 'hooks/useTranslation';

import { useAppData, useUserStore } from 'providers/RootStoreProvider';
import { AppRoutes } from 'enums/routes.enum';
import UnsubscribeForm from 'components/UnsubscribeForm';
import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

const Footer = () => {
    const { t, detect } = useTranslation();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { isDesktop, isLargeDesktop } = useDeviceDetect();
    const isMobile = !isDesktop && !isLargeDesktop;
    const { setIsAccountRedirectDisabled, flowOuter } = useAppData();
    const { user, checkUserSubscription, userSubscription } = useUserStore();

    useEffect(() => {
        if (!user?.email || flowOuter) return;

        checkUserSubscription().catch();
    }, [user?.email]);

    const isAccountPage = pathname === AppRoutes.ACCOUNT;

    const handleLegalRedirect = async (event, path: string) => {
        if (isAccountPage) {
            event.preventDefault();
            await setIsAccountRedirectDisabled(false);
            navigate(path);
        }
    };

    useBeforeUnload(() => {
        if (isAccountPage) {
            setIsAccountRedirectDisabled(true);
        }
    });

    return (
        <Box
            sx={{
                backgroundColor: '#12162B',
                py: '40px',
                px: '20px',
                zIndex: 1,
            }}
            component="footer"
        >
            <Container
                sx={{
                    '&.MuiContainer-root': {
                        px: { xs: '0', lg: '40px' },
                    },
                }}
                id={'contact'}
            >
                <Stack direction={isMobile ? 'column' : 'row'} justifyContent={'space-between'}>
                    <Box>
                        <Logo width={'46px'} height={'46px'} fontSize="24px" sx={{ mb: '30px' }} />
                        <Typography variant="body2" color="#72789A" sx={{ fontSize: '16px', fontWeight: 300, lineHeight: 'normal' }}>
                            {t('Footer_contacts_p1')}
                        </Typography>
                        <Stack direction={flowOuter && !isMobile ? 'row' : 'column'}>
                            <Typography variant="body2" color="#72789A" sx={{ fontSize: '16px', fontWeight: 300, lineHeight: 'normal', marginRight: '6px' }}>
                                {t('Footer_contacts_p2')}
                            </Typography>
                            <Stack direction={'row'} spacing="4px" sx={{ mb: flowOuter && !isMobile ? '30px' : '20px' }}>
                                <Link to="mailto:support@locatemate.co" style={{ textDecoration: 'none' }}>
                                    <Typography
                                        variant="body2"
                                        color="#72789A"
                                        align="left"
                                        sx={{ fontSize: '16px', fontWeight: 300, lineHeight: 'normal', textDecoration: 'underline' }}
                                    >
                                        support@locatemate.co
                                    </Typography>
                                </Link>
                            </Stack>
                        </Stack>
                        <Typography variant="body2" color="#a3a5b0" sx={{ mb: '20px', fontSize: '18px', fontWeight: 500 }}>
                            {t('Footer_contacts_secure')}
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', mb: '40px', gap: '16px' }}>
                            <img src={'/images/visa.svg'} alt="visa" width={'64px'} height={'32px'} />
                            <img src={'/images/mastercard.svg'} alt="mastercard" width={'64px'} height={'32px'} />
                        </Box>
                        {!flowOuter && userSubscription?.isPremium && (
                            <>
                                <Typography variant="body2" color="#a3a5b0" align="left" sx={{ mb: '4px', fontSize: '16px', fontWeight: 300 }}>
                                    If you want to unsubscribe, please click the button below and submit the form
                                </Typography>
                                <UnsubscribeForm />
                            </>
                        )}
                    </Box>
                    <Stack
                        direction={'column'}
                        justifyContent={'flex-start'}
                        alignItems={'flex-start'}
                        spacing={'20px'}
                        sx={{
                            mt: flowOuter && !isMobile ? '76px' : '0',
                            mb: '40px',
                            padding: detect.isArabicLanguage ? '0 0 0 45px' : '0 45px 0 0',
                        }}
                    >
                        <Typography variant="body2" color="#a3a5b0" sx={{ fontSize: '18px', fontWeight: 500, lineHeight: '1.25' }}>
                            {t('Legal info')}:
                        </Typography>
                        <Link to="/cookie-policy" style={{ textDecoration: 'none' }} onClick={(e) => handleLegalRedirect(e, AppRoutes.COOKIE_POLICY)}>
                            <Typography color="#72789A" sx={{ fontSize: '16px', fontWeight: 300, textDecoration: 'none', lineHeight: '1.25' }}>
                                {t('Cookie Policy')}
                            </Typography>
                        </Link>
                        <Link to="/privacy-policy" style={{ textDecoration: 'none' }} onClick={(e) => handleLegalRedirect(e, AppRoutes.PRIVACY_POLICY)}>
                            <Typography color="#72789A" sx={{ fontSize: '16px', fontWeight: 300, textDecoration: 'none', lineHeight: '1.25' }}>
                                {t('Privacy Policy')}
                            </Typography>
                        </Link>
                        <Link to="/terms-of-use" style={{ textDecoration: 'none' }} onClick={(e) => handleLegalRedirect(e, AppRoutes.TERMS_OF_USE)}>
                            <Typography color="#72789A" sx={{ fontSize: '16px', fontWeight: 300, textDecoration: 'none', lineHeight: '1.25' }}>
                                {t('Terms of Use')}
                            </Typography>
                        </Link>
                    </Stack>
                </Stack>
                <Typography variant="body2" color="#454B6F" align="center" sx={{ fontSize: '16px', fontWeight: 300 }}>
                    {'Copyright © '}
                    {new Date().getFullYear()}
                    {' locatemate.co'}
                </Typography>
            </Container>
        </Box>
    );
};

export default observer(Footer);
