import { Button, Stack, Typography } from '@mui/material';
import { ActionItem, Modal } from 'components/DevToolsWidget/components/shared';
import { useAppData, useUserStore } from 'providers/RootStoreProvider';
import PolicyOutlinedIcon from '@mui/icons-material/PolicyOutlined';
import { useBoolean } from 'hooks/useBoolean';
import { observer } from 'mobx-react-lite';

const FlowOuterConfig = observer(() => {
    const { flowOuter, setFlowOuter } = useAppData();
    const { updateUserWebSession, userWebSession } = useUserStore();
    const [isOpen, setIsOpen] = useBoolean();

    const toggleFlowOuter = async () => {
        if (userWebSession) {
            await updateUserWebSession({
                session: { ...userWebSession?.session, flowOuter: !flowOuter },
            });
        }
        setFlowOuter(!flowOuter);
    };

    return (
        <ActionItem
            tooltipTitle={'FlowOuter Config'}
            icon={
                <>
                    <Stack justifyContent="center" alignItems="center" onClick={setIsOpen.on} sx={{ width: '100%', height: '100%' }}>
                        <PolicyOutlinedIcon sx={(theme) => ({ color: theme.palette.background.blue })} />
                    </Stack>
                    <Modal open={isOpen} onClose={setIsOpen.off}>
                        <Stack
                            direction={'column'}
                            gap="12px"
                            sx={{
                                padding: '10px 0 20px',
                                maxWidth: '400px',
                                margin: '0 auto',
                            }}
                        >
                            <Typography sx={{ textAlign: 'center', fontSize: '24px' }}>
                                FlowOuter is <strong>{flowOuter ? 'ON' : 'OFF'}</strong>
                            </Typography>
                            <Button
                                onClick={toggleFlowOuter}
                                sx={{
                                    padding: '10px 20px',
                                    backgroundColor: 'text.lightBlue',
                                    borderRadius: '12px',
                                    color: 'background.white',
                                    fontSize: '18px',
                                    border: '1px solid transparent',
                                    '&:hover': {
                                        borderColor: '#90caf9',
                                    },
                                }}
                            >
                                Turn {flowOuter ? 'OFF' : 'ON'} flowOuter
                            </Button>
                        </Stack>
                    </Modal>
                </>
            }
        />
    );
});

export default FlowOuterConfig;
