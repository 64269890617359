import React, { useEffect, useLayoutEffect, useState } from 'react';
import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { fetchAndActivate, getAll, getRemoteConfig } from 'firebase/remote-config';
import { observer } from 'mobx-react-lite';
import { v4 as uuidv4 } from 'uuid';

import { useAppData, useUserStore } from 'providers/RootStoreProvider';
import { getAllSearchParams } from 'helpers/getAllSearchParams';
import { ampli } from 'services/ampli';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { app } from 'services/firebase';
import { saveToStorage } from 'services/storage';
import { getDayOfYear } from 'helpers/dateTime';
import { isRecord } from 'helpers/common';
import { isProductionHost } from 'helpers/environment';
import { getIpInfo } from 'http-client/ip-info';
import { RESTRICTED_COUNTRIES } from 'constants/restrictedCountries';
import { PROJECT_NAME } from 'constants/env';
import UAParser from 'ua-parser-js';
import { createUserAttribution, ICreateUserAttributionParams } from 'http-client/attribution.client';
import { handleAmpliLoaded } from 'helpers/handleAmpliLoaded';
import { AppRoutes } from 'enums/routes.enum';

const AmplitudeProvider = observer(({ children }: React.PropsWithChildren) => {
    const [isAmpliLoaded, setIsAmpliLoaded] = useState<boolean>(false);
    const [ampliResponse, setAmpliResponse] = useState<Record<string, any> | null>(null);
    const {
        amplitudeUserId,
        setAmplitudeUserId,
        setAmpliIDLoading,
        flowOuter,
        setFlowOuter,
        abTestData: sessionAbTestData,
        setAbTestData: setSessionAbTestData,
        ipInfo,
        setIpInfo,
    } = useAppData();
    const { user, userProfile, fetchUserSession, checkUserSubscription, userWebSession, updateUserWebSession } = useUserStore();
    const [abTestData, setAbTestData] = useState<Record<string, string> | Record<string, any> | null>(null);
    const navigate = useNavigate();

    const { pathname, hash } = useLocation();
    const [searchParams] = useSearchParams();

    const allQueryParams = getAllSearchParams(searchParams);
    const {
        usid: usidQueryParam,
        flowOuter: flowOuterQueryParam,
        gclid: gclidQueryParam,
        utm_source: utmSourceQueryParam,
        utm_campaign: utmCampaignQueryParam,
        utm_content: utmContentQueryParam,
        utm_term: utmTermQueryParam,
        utm_placement: utmPlacementQueryParam,
        fbclid: fbclidQueryParam,
        utm_medium: utmMediumQueryParam,
        utm_ad: utmAdQueryParam,
        is_test: isTestQueryParam,
    } = allQueryParams as Record<string, string>;

    const usid = userProfile?.amplitudeSessionId || amplitudeUserId || usidQueryParam;
    const sendInitialAttributionEvent =
        (!localStorage.getItem('attributionEventSent') || localStorage.getItem('attributionEventSent') !== usid) && !userProfile?.amplitudeSessionId;

    const isAttributionSent = !(
        (sendInitialAttributionEvent && !userWebSession?.session?.webAttributionSent) ||
        (!userWebSession?.session?.webAttributionSent && userProfile?.amplitudeSessionId)
    );

    const saveAttributionSentState = () => {
        if (user) {
            updateUserWebSession({
                session: { ...userWebSession?.session, webAttributionSent: true },
            });
        }
        localStorage.setItem('attributionEventSent', usid);
    };

    const getAttributionParams = ({
        device_id,
        user_id,
    }: {
        device_id?: string;
        user_id?: string;
    } = {}) => {
        const deviceInfo = new UAParser().getResult();

        const commonUserData: Record<string, any> = {
            device_id,
            user_id,
            country: ipInfo?.country,
            project_name: PROJECT_NAME,
            device_category: deviceInfo.device.type,
            browser: deviceInfo.browser.name,
            browser_version: deviceInfo.browser.version,
            operating_system: deviceInfo.os.name,
            operating_system_version: deviceInfo.os.version,
            device_model: deviceInfo.device.model,
            device_brand: deviceInfo.device.vendor,
            device_language: navigator.language,
            is_test: isTestQueryParam ?? !isProductionHost(),

            utm_source: utmSourceQueryParam,
            utm_medium: utmMediumQueryParam,
            utm_campaign: utmCampaignQueryParam,
            utm_content: utmContentQueryParam,
            utm_ad: utmAdQueryParam,
            utm_term: utmTermQueryParam,
            gclid: gclidQueryParam,
            fbclid: fbclidQueryParam,
            utm_placement: utmPlacementQueryParam,

            initial_utm_placement: utmPlacementQueryParam,
            initial_utm_ad: utmAdQueryParam,
        };

        const initialAmpliUserData = {
            initial_utm_source: utmSourceQueryParam,
            initial_utm_medium: utmMediumQueryParam,
            initial_utm_campaign: utmCampaignQueryParam,
            initial_utm_content: utmContentQueryParam,
            initial_utm_term: utmTermQueryParam,
            initial_gclid: gclidQueryParam,
            initial_fbclid: fbclidQueryParam,
            initial_utm_placement: utmPlacementQueryParam,
            initial_utm_ad: utmAdQueryParam,
            ...commonUserData,
        } as Record<string, string | number | boolean>;

        if (utmSourceQueryParam) {
            initialAmpliUserData.cohort_day = getDayOfYear();
        }

        return {
            initialAmpliUserData,
            commonUserData,
        };
    };

    useEffect(() => {
        if (isTestQueryParam) {
            sessionStorage.setItem('is_test', isTestQueryParam);
        }
    }, [isTestQueryParam]);

    useEffect(() => {
        if (!ampliResponse) {
            return;
        }

        if (!isAttributionSent) {
            const { initialAmpliUserData, commonUserData } = getAttributionParams({
                device_id: ampliResponse.event.device_id,
                user_id: ampliResponse.event.user_id,
            });

            const getAttributes = (data: Record<string, any>) => JSON.parse(JSON.stringify(data));

            handleAmpliLoaded(() => ampli.webAttribution(getAttributes(initialAmpliUserData)));

            if (sendInitialAttributionEvent ?? !userWebSession?.session?.webAttributionSent) {
                createUserAttribution(getAttributes(commonUserData) as ICreateUserAttributionParams);
                saveAttributionSentState();
            }
        }
    }, [ampliResponse]);

    // utm_source=LT_WO_test&utm_campaign=test_campaign&utm_content=test_adset&utm_term=test_keyword

    useEffect(() => {
        if (!ipInfo) {
            (async () => {
                const ip = await getIpInfo(flowOuter ?? flowOuterQueryParam);
                setIpInfo(ip);
            })();
        } else if (RESTRICTED_COUNTRIES.includes(ipInfo?.country)) {
            navigate(AppRoutes.RESTRICTED_ACCESS);
        }
    }, [ipInfo]);

    useEffect(() => {
        /* For debugging purposes */
        (window as any).flowOuter = flowOuter;
    }, [flowOuter]);

    useLayoutEffect(() => {
        (async () => {
            try {
                const remoteConfig = getRemoteConfig(app);
                remoteConfig.settings.minimumFetchIntervalMillis = 3600000; /* 1 hour */

                await fetchAndActivate(remoteConfig);
                const allAbTestFlags = getAll(remoteConfig);

                const abTest1ShowPaypal20240307 = allAbTestFlags['ab_test_1_show_paypal_20240307']?.asString();
                const settingsAmplitudeJSON = allAbTestFlags['settings_amplitude']?.asString();
                const abPriceIdMyPhIn = allAbTestFlags['ab_price_ID_MY_PH_IN_2024_06_07']?.asString();
                const abPriceSgJpTh = allAbTestFlags['ab_price_SG_JP_TH_2024_06_07']?.asString();
                const abApplepay20240814 = allAbTestFlags['ab_applepay_2024_08_14']?.asString();
                const abPaywall = allAbTestFlags['ab_paywall_2024_10_09']?.asString();

                let settingsAmplitude = null;
                if (settingsAmplitudeJSON) {
                    try {
                        settingsAmplitude = JSON.parse(settingsAmplitudeJSON);
                    } catch (e) {
                        console.error("Error while parsing FB config 'settings_amplitude' parameter");
                    }
                }

                setAbTestData((prevState) => {
                    return {
                        ...(prevState || {}),
                        abTest1ShowPaypal20240307,
                        settingsAmplitude,
                        abPriceIdMyPhIn,
                        abPriceSgJpTh,
                        abApplepay20240814,
                        abPaywall,
                    };
                });
            } catch (err) {
                console.error('Failed to fetch Firebase remote config');
            }
        })();
    }, []);

    useLayoutEffect(() => {
        (async () => {
            if (isAmpliLoaded) return;

            try {
                await ampli.load({ environment: 'default' }).promise;
                setIsAmpliLoaded(true);
            } catch (err) {
                console.error('Failed to provide Amplitude initialization');
            }
        })();
    }, [isAmpliLoaded]);

    useLayoutEffect(() => {
        (async () => {
            if (!isAmpliLoaded || !abTestData) return;

            const sessionReplaySettings = isRecord(abTestData.settingsAmplitude) && abTestData.settingsAmplitude.sessionReplay;

            if (
                isRecord(sessionReplaySettings) &&
                ((sessionReplaySettings.allowedProduction && isProductionHost()) || (sessionReplaySettings.allowedDevelopment && !isProductionHost()))
            ) {
                ampli.client.add(sessionReplayPlugin({ sampleRate: sessionReplaySettings.sampleRate }));
            }

            try {
                const usid = userProfile?.amplitudeSessionId || amplitudeUserId || usidQueryParam || uuidv4();

                const [userSessionResponse, userSubscriptionResponse] = await Promise.allSettled([fetchUserSession(), checkUserSubscription()]);
                const userSession = userSessionResponse.status === 'fulfilled' ? userSessionResponse.value || {} : {};
                const userSubscription = userSubscriptionResponse.status === 'fulfilled' ? userSubscriptionResponse.value : {};

                const ampliIdentifyData = {} as Record<string, any>;

                /* Setting up Amplitude User properties only if they have meaningful value */

                if (utmSourceQueryParam) ampliIdentifyData.cohort_day = getDayOfYear();

                /* Try to get ab test data from previously stored session data if it exists */
                const sessionAbTestData = userSession?.record?.abTestData || {};
                const abTest1ShowPaypal20240307 = sessionAbTestData.abTest1ShowPaypal20240307 || abTestData.abTest1ShowPaypal20240307;
                const abPriceIdMyPhIn = sessionAbTestData.abPriceIdMyPhIn || abTestData.abPriceIdMyPhIn;
                const abPriceSgJpTh = sessionAbTestData.abPriceSgJpTh || abTestData.abPriceSgJpTh;
                const abApplepay20240814 = sessionAbTestData.abApplepay20240814 || abTestData.abApplepay20240814;
                const abPaywall = sessionAbTestData.abPaywall || abTestData.abPaywall;

                ampliIdentifyData.ab_test_1_show_paypal_20240307 = abTest1ShowPaypal20240307;
                ampliIdentifyData.ab_price_ID_MY_PH_IN_2024_06_07 = abPriceIdMyPhIn;
                ampliIdentifyData.ab_price_SG_JP_TH_2024_06_07 = abPriceSgJpTh;
                ampliIdentifyData.ab_applepay_2024_08_14 = abApplepay20240814;
                ampliIdentifyData.ab_paywall_2024_10_09 = abPaywall;

                const isPremium = userSubscription?.isPremium;
                const userEmail = user?.email;

                if (typeof isPremium == 'boolean') {
                    ampliIdentifyData.subscription_active = isPremium;
                    const productId = userSubscription?.info?.productId;
                    if (productId) {
                        ampliIdentifyData.product_id = productId;
                    }
                }

                if (userEmail) {
                    ampliIdentifyData.user_email = userEmail;
                }

                const { initialAmpliUserData, commonUserData } = getAttributionParams({ user_id: usid });
                const userProps = !isAttributionSent ? initialAmpliUserData : commonUserData;

                const ampliIdentifyResponse = await ampli.identify(usid, JSON.parse(JSON.stringify({ ...ampliIdentifyData, ...userProps }))).promise;
                setAmpliResponse(ampliIdentifyResponse as Record<string, any>);
                sessionStorage.setItem('deviceId', (ampliIdentifyResponse as Record<string, any>).event.device_id);
                setAmplitudeUserId(usid);
            } catch (err) {
                console.error('Failed to provide Amplitude identification');
            }
        })();
    }, [isAmpliLoaded, userProfile?.amplitudeSessionId, abTestData]);

    useLayoutEffect(() => {
        /*
         * Saving into store and removing 'flowOuter' search
         * parameter from browser's URL bar on initial visit
         */
        if (!flowOuter && flowOuterQueryParam === 'true') {
            saveToStorage('flowOuter', '1');
            setFlowOuter(true);
        }

        if (gclidQueryParam) {
            saveToStorage('gclid', gclidQueryParam);
        }

        const isLegalPages = [AppRoutes.TERMS_OF_USE, AppRoutes.PRIVACY_POLICY, AppRoutes.COOKIE_POLICY].includes(pathname as AppRoutes);

        /*
         *  Here we are visually updating 'usid' search param (usidQueryParam) in
         *  browsers URL bar when page navigation happens or if amplitudeUserId has changed.
         *  With exception of this AmplitudeProvider module, 'usid' search param should not be used
         *  as "source of truth" elsewhere inside application logic, use amplitudeUserId instead.
         */
        if (!isLegalPages && isAmpliLoaded && amplitudeUserId && amplitudeUserId !== usidQueryParam && abTestData) {
            const newQueryParams = { ...allQueryParams };

            if (flowOuterQueryParam) {
                delete (newQueryParams as any).flowOuter;
            }

            newQueryParams['usid'] = amplitudeUserId;

            navigate(
                {
                    pathname,
                    hash,
                    search: createSearchParams(newQueryParams).toString(),
                },
                { replace: true, preventScrollReset: true }
            );
        }

        /* Send amplitude page navigation event on last update */
        if (isAmpliLoaded && amplitudeUserId && amplitudeUserId === usidQueryParam && abTestData) {
            /* Saving AB test data into store */
            if (!sessionAbTestData && abTestData) {
                setSessionAbTestData(abTestData);
            }

            setAmpliIDLoading(false);

            // const params = {
            //     url: window.location.href,
            //     path: window.location.pathname,
            //     userId: amplitudeUserId || '0',
            //     userEmail: user?.email || 'guest',
            // };
            //
            // ampli.pageView(params);
        }
    }, [isAmpliLoaded, pathname, amplitudeUserId, usidQueryParam, flowOuterQueryParam, gclidQueryParam, abTestData]);

    if (!isAmpliLoaded) {
        return null;
    }

    return <>{children}</>;
});

export default AmplitudeProvider;
