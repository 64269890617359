import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { Stack, SxProps, Theme, Typography } from '@mui/material';

import { useUserStore } from 'providers/RootStoreProvider';
import { AppRoutes } from 'enums/routes.enum';

interface LogoProps {
    width?: string;
    height?: string;
    fontSize?: string;
    fontWeight?: number;
    sx?: SxProps<Theme>;
}

const Logo = observer(({ width, height, fontSize, fontWeight, sx }: LogoProps) => {
    const { user } = useUserStore();
    const isLoggedIn = !!user?.email;

    return (
        <Link to={isLoggedIn ? AppRoutes.ACCOUNT : AppRoutes.HOME} style={{ textDecoration: 'none', color: '#fff' }}>
            <Stack direction={'row'} alignItems={'center'} gap={1} sx={sx}>
                <img src={'/images/logo.svg'} alt="logo" width={width} height={height} />
                <Typography variant={'h6'} component={'h1'} sx={{ textDecoration: 'none', fontWeight: fontWeight || 600, fontSize: fontSize || '18px' }}>
                    LocateMate
                </Typography>
            </Stack>
        </Link>
    );
});

export default Logo;
