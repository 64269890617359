import { EndpointEnum } from 'enums/endpoint.enum';
import { post } from './http.client';
import { isResponseOk } from 'helpers/isResponseOk';

export const getAuthToken = async (uid: string, id: string) => {
    try {
        const response = await post(`${EndpointEnum.SIGNUP}`, {
            uid,
            id,
        });

        if (isResponseOk(response)) {
            return response?.data;
        }
    } catch (e) {
        console.error('error:', e);
    }
};

export const getSignInToken = async (uid: string, id: string) => {
    try {
        const response = await post(`${EndpointEnum.SIGNIN}`, {
            uid,
            id,
        });
        if (isResponseOk(response)) {
            return response?.data;
        }
    } catch (e) {
        console.error('error:', e);
    }
};

export const refreshAuthToken = async (refreshToken: string) => {
    try {
        const response = await post(`${EndpointEnum.REFRESH_TOKEN}`, {
            refreshToken,
        });
        if (isResponseOk(response)) {
            return response?.data;
        }
    } catch (e) {
        console.error('error:', e);
    }
};
