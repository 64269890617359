import { EndpointEnum } from 'enums/endpoint.enum';
import { deleteApi, get, post } from './http.client';
import { isResponseOk } from 'helpers/isResponseOk';
import { ILocation, LocationType } from '../interfaces/location/location.interface';

type CreateLocation = Required<Pick<ILocation, 'name' | 'type'>>;

interface CreateFindDevice extends CreateLocation {
    type: LocationType.FIND_DEVICE;
}

interface LocationsQuery extends Partial<Pick<ILocation, 'type'>> {
    status?: string;
}

export const getLocationLink = async (data: CreateFindDevice) => {
    return post(`${EndpointEnum.LOCATIONS}/link`, data);
};

export type UpdateLocationProps = Required<Pick<ILocation, 'latitude' | 'longitude' | 'target'>>;

export const updateLocation = async (id: string, data: UpdateLocationProps) => {
    return post(`${EndpointEnum.LOCATIONS}/${id}`, data);
};

export const getLocation = async (locationId: string) => {
    try {
        const response = await get(`${EndpointEnum.LOCATIONS}/${locationId}`, {});
        if (isResponseOk(response)) {
            return response?.data;
        }
    } catch (e) {
        console.error('error:', e);
    }
};

export const getLocations = async (query?: LocationsQuery) => {
    try {
        const response = await get(`${EndpointEnum.LOCATIONS}`, {
            type: query?.type,
            status: query?.status,
        });
        if (isResponseOk(response)) {
            return response?.data;
        }
    } catch (e) {
        console.error('error:', e);
    }
};

export const renameLocation = async (id: string, name: string) => {
    return post<ILocation>(`${EndpointEnum.LOCATIONS}/${id}/rename`, { name });
};

export const deleteLocation = async (id: string) => {
    return deleteApi(`${EndpointEnum.LOCATIONS}/${id}`);
};

export interface SendLocationSmsProps {
    locationId: string;
    language: string;
    flowOuter: boolean;
}

export const sendLocationSms = async ({ locationId, language, flowOuter }: SendLocationSmsProps) => {
    return post(`${EndpointEnum.LOCATIONS}/${locationId}/sms`, {
        project: 'locatemate',
        language,
        flowOuter,
    });
};
