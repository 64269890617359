import { Languages } from 'enums/languages.enum';
import { useTranslation as useI18nTranslation } from 'react-i18next';

export const useTranslation = () => {
    const translation = useI18nTranslation();
    const currentLanguage = translation.i18n.language.toLowerCase();

    const detect = {
        isEnglishLanguage: currentLanguage === Languages.en,
        isSpanishLanguage: currentLanguage === Languages.es,
        isFrenchLanguage: currentLanguage === Languages.fr,
        isPortugueseLanguage: currentLanguage === Languages.pt,
        isArabicLanguage: currentLanguage === Languages.sa,
        isVietnameseLanguage: currentLanguage === Languages.vn,
        isTurkishLanguage: currentLanguage === Languages.tr,
        isJapaneseLanguage: currentLanguage === Languages.jp,
        isIndonesianLanguage: currentLanguage === Languages.id,
    };

    return { ...translation, detect };
};
