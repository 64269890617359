import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

export const StyledMenuButton = styled(Button)({
    textTransform: 'none',
    color: '#FFF',
    fontSize: '18px',
    fontWeight: 500,
    borderRadius: '34px',
    border: '2px solid rgba(1, 207, 254, 0.50)',
    background: 'linear-gradient(128deg, rgba(10, 38, 147, 0.10) 0%, rgba(1, 207, 254, 0.10) 70.88%, rgba(242, 211, 205, 0.10) 96.12%)',
    justifyContent: 'start',
    '&:hover': {
        borderWidth: '2px',
    },

    '& .MuiButton-startIcon:dir(rtl)': {
        marginLeft: '8px',
        marginRight: '-4px',
    },
});
