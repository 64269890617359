import PageFallback from 'components/PageFallback';
import { lazy, Suspense } from 'react';

let Unsubscribe = lazy(() => import('./Unsubscribe'));

export async function lazyUnsubscribeLoader() {
    const componentModule = await import('./Unsubscribe');
    // This avoid flicker from React.lazy by using the component directly
    Unsubscribe = componentModule.default as any;

    return null;
}

export function LazyUnsubscribe() {
    return (
        <Suspense fallback={<PageFallback />}>
            <Unsubscribe />
        </Suspense>
    );
}
