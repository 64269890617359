import { lazy, Suspense } from 'react';
import PageFallback from 'components/PageFallback';

let FindDevice = lazy(() => import('./FindDevice'));

export async function lazyFindDeviceLoader() {
    const componentModule = await import('./FindDevice');
    FindDevice = componentModule.default as any;

    return null;
}

export function LazyFindDevice() {
    return (
        <Suspense fallback={<PageFallback />}>
            <FindDevice />
        </Suspense>
    );
}
