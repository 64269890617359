import CookiePolicy, { cookiePolicyLoader } from 'pages/CookiePolicy';
import ForgotPassword, { forgotPasswordLoader } from 'pages/ForgotPassword';
import LogIn, { logInLoader } from 'pages/LogIn';
import Main, { mainLoader } from 'pages/Main';
import NewPassword, { newPasswordLoader } from 'pages/NewPassword';
import Payment, { paymentLoader } from 'pages/Payment';
import PrivacyPolicy, { privacyPolicyLoader } from 'pages/PrivacyPolicy';
import Share, { shareLoader } from 'pages/Share';
import SuccessPayment, { successPaymentLoader } from 'pages/SuccessPayment';
import TermsOfUse, { termsOfUseLoader } from 'pages/TermsOfUse';
import SignUp, { signUpLoader } from 'pages/SignUp';
import DeclinePayment, { declinePaymentLoader } from 'pages/DeclinePayment';
import FakeSearch, { fakeSearchLoader } from 'pages/FakeSearch';
import { AccountLayout, FindDevice, findDeviceLoader } from 'pages/Account';
import RestrictedAccessByIp, { restrictedAccessByIpLoader } from 'pages/RestrictedAccessByIp';
import { AppRoutes } from 'enums/routes.enum';
import OauthPage, { oauthLoader } from 'pages/Oauth';
import Unsubscribe, { unsubscribeLoader } from 'pages/ContactForm';

const routes = [
    {
        path: AppRoutes.HOME,
        element: <Main />,
        loader: mainLoader,
    },
    {
        path: `${AppRoutes.SHARE}/:locationId`,
        element: <Share />,
        loader: shareLoader,
    },
    {
        path: AppRoutes.COOKIE_POLICY,
        element: <CookiePolicy />,
        loader: cookiePolicyLoader,
    },
    {
        path: AppRoutes.PRIVACY_POLICY,
        element: <PrivacyPolicy />,
        loader: privacyPolicyLoader,
    },
    {
        path: AppRoutes.TERMS_OF_USE,
        element: <TermsOfUse />,
        loader: termsOfUseLoader,
    },
    {
        path: AppRoutes.LOG_IN,
        element: <LogIn />,
        loader: logInLoader,
    },
    {
        path: AppRoutes.SIGN_UP,
        element: <SignUp />,
        loader: signUpLoader,
    },
    {
        path: AppRoutes.FORGOT_PASSWORD,
        element: <ForgotPassword />,
        loader: forgotPasswordLoader,
    },
    {
        path: AppRoutes.NEW_PASSWORD,
        element: <NewPassword />,
        loader: newPasswordLoader,
    },
    {
        path: AppRoutes.PAYMENT,
        element: <Payment />,
        loader: paymentLoader,
    },
    {
        path: AppRoutes.SUCCESS,
        element: <SuccessPayment />,
        loader: successPaymentLoader,
    },
    {
        path: AppRoutes.DECLINE,
        element: <DeclinePayment />,
        loader: declinePaymentLoader,
    },
    {
        path: AppRoutes.SEARCH,
        element: <FakeSearch />,
        loader: fakeSearchLoader,
    },
    {
        path: AppRoutes.ACCOUNT,
        element: <AccountLayout />,
        children: [
            {
                index: true,
                element: <FindDevice />,
                loader: findDeviceLoader,
            },
        ],
    },
    {
        path: '*',
        element: <Main />,
        loader: mainLoader,
    },
    {
        path: AppRoutes.RESTRICTED_ACCESS,
        element: <RestrictedAccessByIp />,
        loader: restrictedAccessByIpLoader,
    },
    {
        path: `${AppRoutes.OAUTH}/:provider`,
        element: <OauthPage />,
        loader: oauthLoader,
    },
    {
        path: AppRoutes.UNSUBSCRIBE,
        element: <Unsubscribe />,
        loader: unsubscribeLoader,
    },
];

export default routes;
