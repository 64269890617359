import { FirebaseConfig } from './firebaseConfig';
import { FlowOuterConfig } from './flowOuterConfig';
import { HideDevTools } from './hide';

const features = [
    { id: 'hide-dev-tools', component: HideDevTools },
    { id: 'firebase-config', component: FirebaseConfig },
    { id: 'flow-outer-config', component: FlowOuterConfig },
];

export default features;
