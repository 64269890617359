import { isToday } from 'helpers/isToday';
import { deleteLocation, getLocations, renameLocation } from 'http-client/location.client';
import { ILocation } from 'interfaces/location/location.interface';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { RootStore } from './root.store';

export class LocationsStore {
    rootStore: RootStore;
    dailyLocationsMap = new Map();
    locations: ILocation[] = [];
    location: ILocation | null = null;
    limit = 24;
    overLimit = false;
    loading = false;

    constructor(rootStore: RootStore) {
        makeObservable(this, {
            locations: observable,
            location: observable,
            loading: observable,
            overLimit: observable,
            limit: observable,
            dailyLocationsMap: observable,
            setLocation: action,
            setLoading: action,
            deleteLocationAction: action,
            renameLocationAction: action,
        });
        this.rootStore = rootStore;

        this.loadDailyLocations();
    }

    setLocation = (location: ILocation | null) => {
        this.location = location;
    };

    private setLocations = (locations: ILocation[]) => {
        this.locations = locations;
    };

    setLoading = (loading: boolean) => {
        this.loading = loading;
    };

    fetchLocations = async (...payload: Parameters<typeof getLocations>) => {
        this.setLoading(true);
        const locations = await getLocations(...payload);

        runInAction(() => {
            this.updateDailyLocations(locations);
            this.setLocations(locations || []);
            this.checkLimit();
            this.setLoading(false);
        });
    };

    fetchAllLocations = async () => {
        const locations = await getLocations();
        runInAction(() => {
            this.updateDailyLocations(locations);
            this.checkLimit();
        });
    };

    deleteLocationAction = async (id: string) => {
        await deleteLocation(id);
        runInAction(() => {
            this.locations = this.locations.filter((location) => location.id !== id);
        });
    };

    renameLocationAction = async (id: string, name: string) => {
        const response = await renameLocation(id, name);
        const updatedLocation = response?.data;

        runInAction(() => {
            if (updatedLocation) {
                this.locations = this.locations.map((location) => {
                    if (location.id === updatedLocation.id) {
                        return updatedLocation;
                    }

                    return location;
                });
            }
        });
    };

    checkLimit = () => {
        this.overLimit = this.dailyLocationsMap.size >= this.limit;
    };

    private loadDailyLocations() {
        const data = localStorage.getItem('DL');
        if (data) {
            const locationsArray = JSON.parse(data);
            this.dailyLocationsMap = new Map(locationsArray.map((item) => [item.id, item]));
            this.cleanOldLocations();
        }
    }

    private updateDailyLocations(locations) {
        locations?.forEach((location) => {
            if (isToday(location.createdAt)) {
                this.dailyLocationsMap.set(location.id, {
                    id: location.id,
                    createdAt: location.createdAt,
                });
            }
        });

        const locationsArray = Array.from(this.dailyLocationsMap.values());
        localStorage.setItem('DL', JSON.stringify(locationsArray));
    }

    private cleanOldLocations() {
        this.dailyLocationsMap.forEach((location, id) => {
            if (!isToday(location.createdAt)) {
                this.dailyLocationsMap.delete(id);
            }
        });
    }

    hydrate = (data: any) => {
        if (!data) {
            return;
        }
        if (data.locations) {
            this.locations = data.locations;
        }
    };
}
