import { useMediaQuery } from '@mui/material';

const useDeviceDetect = () => {
    const isMobile = useMediaQuery('(max-width:480px)');
    const isTablet = useMediaQuery('(max-width:1023px)');
    const isDesktop = useMediaQuery('(min-width:769px) and (max-width:1024px)');
    const isLargeDesktop = useMediaQuery('(min-width:1025px)');
    const isExtraLargeDesktop = useMediaQuery('(min-width:1121px)');

    return { isMobile, isTablet, isDesktop, isLargeDesktop, isExtraLargeDesktop };
};

export default useDeviceDetect;
