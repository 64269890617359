import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { AppBar, Box, Button, Container, Drawer, IconButton, Stack, Toolbar, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ampli } from 'services/ampli';
import { useUserStore } from 'providers/RootStoreProvider';
import Logo from 'components/Logo/Logo';
import GradientButton from 'components/GradienButton';
import LogOutIcon from 'components/icons/LogOutIcon';
import { LanguagePicker } from 'components/Header/LanguagePicker';
import { AppRoutes } from 'enums/routes.enum';
import { removeTokensFromStorage } from 'services/storage';
import { supabase } from 'services/supabase';
import { StyledMenuButton } from './BurgerMenu.styles';

interface Props {
    open: boolean;
    anchor: 'left' | 'right' | 'top' | 'bottom';
    onClose: () => void;
}

const BurgerMenu = ({ open, onClose, anchor }: Props) => {
    const navigate = useNavigate();
    const { user, setUser, setUserProfile } = useUserStore();
    const { t, i18n } = useTranslation();
    const [languagePickerOpen, setLanguagePickerOpen] = useState(false);
    const languagePickerRef = useRef<HTMLDivElement | null>(null);

    const isLoggedIn = !!user?.email;

    const handleClickOutsideLanguagePicker = (event: MouseEvent) => {
        const target = event.target as HTMLElement;

        if (languagePickerRef.current && !languagePickerRef.current.contains(target)) {
            setLanguagePickerOpen(false);
        }
    };

    const handleLanguagePickerClick = () => setLanguagePickerOpen(!languagePickerOpen);

    useEffect(() => {
        if (languagePickerOpen) {
            document.addEventListener('click', handleClickOutsideLanguagePicker);
        } else {
            document.removeEventListener('click', handleClickOutsideLanguagePicker);
        }

        return () => {
            document.removeEventListener('click', handleClickOutsideLanguagePicker);
        };
    }, [languagePickerOpen]);

    const onHowItWorksClick = () => {
        onClose();
    };

    const onContactClick = () => {
        onClose();
    };

    const onLogInClick = () => {
        onClose();
        navigate(AppRoutes.LOG_IN);
    };

    const handleLogout = () => {
        supabase.auth
            .signOut()
            .then(() => {
                // Sign-out successful
                if (ampli.isLoaded) {
                    ampli.accountLogoutClicked();
                }

                setUser(null);
                setUserProfile(null);
                removeTokensFromStorage();

                window.location.assign(AppRoutes.LOG_IN);
            })
            .catch((error) => {
                console.error('error: ', error);
            });

        localStorage.setItem('existingUserLoggedOut', 'true');
    };

    return (
        <Drawer
            anchor={anchor}
            open={open}
            onClose={onClose}
            variant="temporary"
            sx={{
                top: 100,
                width: '100vw',
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    // top: 'auto',
                    width: '100vw',
                    maxWidth: '100vw',
                    boxSizing: 'border-box',
                    background: '#171B31',
                },
            }}
        >
            <AppBar position="static" sx={{ background: '#171B31', boxShadow: 'none', p: '13px 20px' }}>
                <Container
                    sx={{
                        '&.MuiContainer-root': {
                            px: { xs: '0', lg: '40px' },
                        },
                    }}
                >
                    <Toolbar sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', p: '0', minHeight: '34px' }}>
                        <Logo width={'32px'} height={'32px'} />
                        <Stack direction="row">
                            <Box sx={{ position: 'relative' }} ref={languagePickerRef}>
                                <Button
                                    style={{
                                        color: '#fff',
                                        fontSize: '18px',
                                        minWidth: '44px',
                                        fontWeight: 300,
                                    }}
                                    onClick={handleLanguagePickerClick}
                                >
                                    {i18n.language}
                                </Button>
                                {languagePickerOpen && <LanguagePicker onClose={() => setLanguagePickerOpen(false)} />}
                            </Box>
                            <IconButton size="large" edge="end" color="inherit" aria-label="menu" onClick={onClose} sx={{ py: 0 }}>
                                <CloseIcon />
                            </IconButton>
                        </Stack>
                    </Toolbar>
                </Container>
            </AppBar>
            {isLoggedIn && (
                <Stack direction={'column'} alignItems={'center'} sx={{ height: 'calc(100% - 60px)' }}>
                    <Box
                        sx={{
                            pt: { xs: '50px', md: '100px' },
                            pb: { xs: '50px', md: '134px' },
                        }}
                    >
                        <Stack direction={'column'} alignItems={'center'}>
                            <img src={'/images/icon_user.svg'} alt="icon_user" />
                            <Typography
                                color="#fff"
                                align="left"
                                sx={{
                                    fontSize: { xs: '5vw', sm: '34px' },
                                    fontWeight: 700,
                                    mt: '20px',
                                    wordWrap: 'break-word',
                                    maxWidth: 'calc(100vw - 40px)',
                                }}
                            >
                                {user?.email}
                            </Typography>
                        </Stack>
                    </Box>
                    <Box
                        sx={{
                            background: '#0C0F21',
                            borderRadius: '30px 30px 0px 0px',
                            p: '26px 20px',
                            width: '100%',
                            height: 'calc(100% - 252px)',
                        }}
                    >
                        {' '}
                        <Container
                            sx={{
                                '&.MuiContainer-root': {
                                    px: { xs: '0', lg: '40px' },
                                },
                            }}
                        >
                            <Stack
                                direction={'column'}
                                alignItems={{
                                    xs: 'center',
                                    md: 'flex-start',
                                }}
                                spacing={'20px'}
                                width={'350px'}
                            >
                                {/* <StyledMenuButton variant="outlined" fullWidth startIcon={<WalletIcon />}>
                                My subscription
                            </StyledMenuButton> */}
                                <StyledMenuButton variant="outlined" fullWidth startIcon={<LogOutIcon />} onClick={handleLogout}>
                                    {t('Log out')}
                                </StyledMenuButton>
                            </Stack>
                        </Container>
                    </Box>
                </Stack>
            )}
            {!isLoggedIn && (
                <Stack direction={'column'} alignItems={'center'} sx={{ height: 'calc(100% - 60px)', pt: '164px' }}>
                    <HashLink to="/#how_it_works" style={{ textDecoration: 'none', marginBottom: '20px' }} onClick={onHowItWorksClick}>
                        <Typography color="#fff" align="left" sx={{ fontSize: '22px', fontWeight: 500, textDecoration: 'none' }}>
                            {t('How it works')}
                        </Typography>
                    </HashLink>
                    <HashLink to="/#contact" style={{ textDecoration: 'none', marginBottom: '40px' }} onClick={onContactClick}>
                        <Typography color="#fff" align="left" sx={{ fontSize: '22px', fontWeight: 500, textDecoration: 'none' }}>
                            {t('Contact')}
                        </Typography>
                    </HashLink>
                    <GradientButton
                        onClick={onLogInClick}
                        sx={{
                            mt: '20px',
                            width: '350px',
                        }}
                    >
                        {t('Log in')}
                    </GradientButton>
                </Stack>
            )}
        </Drawer>
    );
};

export default BurgerMenu;
