import { FC } from 'react';
import { Dialog, DialogProps, IconButton, Portal } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

interface Props extends DialogProps {
    onClose: () => void;
}

export const Modal: FC<Props> = ({ onClose, open, children }) => {
    return (
        <Portal>
            <Dialog
                open={open}
                onClose={onClose}
                sx={{
                    '& .MuiPaper-root': {
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                    },
                    '& .MuiDialog-paper': {
                        borderRadius: '1rem',
                        padding: '1rem',
                        width: '650px',
                        maxWidth: '100%',
                    },
                }}
            >
                <IconButton
                    className="button--close"
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        width: '24px',
                        height: '24px',
                        ml: 'auto',
                        padding: 0,
                    }}
                >
                    <CloseOutlinedIcon />
                </IconButton>
                {children}
            </Dialog>
        </Portal>
    );
};
