import axios from 'axios';
import Cookies from 'js-cookie';
import { PAYMENT_SERVER_URL, PROJECT_NAME } from 'constants/env';
import { EndpointsPayment } from 'enums/endpoint.enum';
import { UserProfile } from 'types/user';
import { getIpInfo } from './ip-info';
import { IpInfoRecordData } from 'stores/user.store';

const paymentServerInstance = axios.create({
    baseURL: PAYMENT_SERVER_URL,
});

export enum PaymentProvider {
    MAXPAY = 'maxpay',
    PAYPAL = 'paypal',
    APPLEPAY = 'applepay-maxpay',
}

export interface IGetPaymentSubscriptionPlansParams {
    project: string;
    provider: PaymentProvider;
    countryCode: string;
    isTest: boolean;
}

export const getPaymentSubscriptionPlans = async (queryParams: IGetPaymentSubscriptionPlansParams) => {
    try {
        const response = await paymentServerInstance.get(EndpointsPayment.SUBSCRIPTION_PLANS_GET, {
            params: queryParams,
        });
        if (response.status === 200 || response.status === 201) {
            return response?.data;
        }

        return null;
    } catch (e) {
        console.error('Cannot get ixopay subscription plans');
        return null;
    }
};

export const fetchPaymentUserInfo = async (email?: string): Promise<UserProfile | null> => {
    let data: any | null = null;

    if (email) {
        data = await checkPaymentUserInfo(email);
    }

    return data;
};

export const checkPaymentUserInfo = async (email: string) => {
    try {
        const response = await paymentServerInstance.get(EndpointsPayment.USER_GET, {
            params: {
                project: PROJECT_NAME,
                email,
            },
        });

        if (response.status === 200 || response.status === 201) {
            return response?.data;
        }

        return null;
    } catch (e) {
        console.error('Cannot get payment server user info');
        return null;
    }
};

export const checkPaymentOneClickInfo = async (email: string) => {
    try {
        const response = await paymentServerInstance.get(EndpointsPayment.PAYMENT_ONE_CLICK_GET, {
            params: {
                email,
            },
        });

        if (response.status === 200 || response.status === 201) {
            return response?.data;
        }

        return null;
    } catch (e) {
        console.error('Cannot get payment one click info');
        return null;
    }
};

export const checkPaymentUserSubscription = async (email: string) => {
    try {
        const response = await paymentServerInstance.get(EndpointsPayment.USER_SUBSCRIPTION_GET, {
            params: {
                project: PROJECT_NAME,
                email,
            },
        });

        if (response.status === 200 || response.status === 201) {
            return response?.data;
        }

        return null;
    } catch (e) {
        console.error('Cannot get payment server user subscription');
        return null;
    }
};

interface CreateUserParams {
    email: string;
    flowOuter: boolean;
    amplitudeSessionId: string;
    countryCode: string;
    ipInfoRecordData?: IpInfoRecordData | null;
    gclid: string | null;
    authSimple: boolean;
    isTest: boolean;
}

export const postCreatePaymentUser = async (params: CreateUserParams): Promise<[{ id: string; email: string }, IpInfoRecordData] | null> => {
    const ipInfoDefaultData = { ip: null, city: null, country: null, postal: null, loc: null, timezone: null };
    const { email, flowOuter, amplitudeSessionId, countryCode, ipInfoRecordData, gclid, authSimple, isTest } = params;
    const fbc = Cookies.get('_fbc');
    const fbp = Cookies.get('_fbp');
    let ipInfo: IpInfoRecordData;

    if (ipInfoRecordData) {
        ipInfo = ipInfoRecordData;
    } else {
        ipInfo = (await getIpInfo(flowOuter)) || ipInfoDefaultData;
    }

    const { ip, postal, country, city, state } = ipInfo;

    try {
        const response = await paymentServerInstance.post(EndpointsPayment.USER_CREATE, {
            email,
            isLegal: !flowOuter,
            project: PROJECT_NAME,
            amplitudeSessionId,
            ip,
            fbc: fbc || null,
            fbp: fbp || null,
            city,
            postalCode: postal,
            countryCode: countryCode || country,
            gclid,
            // loc,
            // timezone,
            userAgent: window?.navigator.userAgent,
            authSimple,
            isTest,
            state,
        });

        if (response.status === 200 || response.status === 201) {
            return [response?.data, ipInfo];
        }

        return null;
    } catch (e) {
        console.error('error:', e);
        return null;
    }
};

interface BaseTransactionParams {
    userId: string;
    email: string;
    ccToken: string;
    successUrl: string;
    errorUrl: string;
    cancelUrl: string;
    cardEntered: boolean;
}

export interface MakeSubscriptionTransactionParams extends BaseTransactionParams {
    subscriptionPlanId: string;
    project: string;
}

export const makeSubscriptionTransaction = async (params: MakeSubscriptionTransactionParams) => {
    try {
        const response = await paymentServerInstance.post(EndpointsPayment.SUBSCRIPTION_TRANSACTION_CREATE, { ...params, project: PROJECT_NAME });

        if (response.status === 200 || response.status === 201) {
            return response?.data;
        }

        return null;
    } catch (e) {
        console.error('Cannot create subscription transaction');
        // console.error('error:', e);
        return null;
    }
};

export interface MakeInappTransactionParams extends BaseTransactionParams {
    priceVatTotal: number;
    vatRate: number;
    priceTotal: number;
    purchaseData: any[];
}

export const makeInappTransaction = async (params: MakeInappTransactionParams) => {
    try {
        const response = await paymentServerInstance.post(EndpointsPayment.INAPP_TRANSACTION_CREATE, params);

        if (response.status === 200 || response.status === 201) {
            return response?.data;
        }

        return null;
    } catch (e) {
        console.error('Cannot create inapp transaction');
        // console.error('error:', e);
        return null;
    }
};

export interface IPaypalCreateSubscriptionParams {
    userId: string;
    paypalSubId: string;
    planId: string;
}

export const createPaypalSubscription = async (body: IPaypalCreateSubscriptionParams) => {
    try {
        const response = await paymentServerInstance.post(`${EndpointsPayment.CREATE_PAYPAL_SUBSCRIPTION}`, body);
        if (response.status === 200 || response.status === 201) {
            return response?.data;
        }

        return null;
    } catch (e) {
        return null;
    }
};
