import { Backdrop, CircularProgress, Stack } from '@mui/material';

const PageFallback = () => {
    return (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, minHeight: '70vh' }} open>
            <Stack alignItems="center" justifyContent="center" color="#fff" sx={{ minHeight: '70vh' }}>
                <CircularProgress size={28} color="inherit" />
            </Stack>
        </Backdrop>
    );
};

export default PageFallback;
